export const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (value, item, index) => {
      return index + 1
    },
  },
  // {
  //   title: '产品编号',
  //   dataIndex: 'goods_number',
  //   key: 'goods_number',
  // },
  {
    title: '分类名称',
    dataIndex: 'category_name',
    key: 'category_name',
  },
  {
    title: '库存数量',
    dataIndex: 'total_quantity',
    key: 'total_quantity',
  },
  {
    title: '库存状态',
    dataIndex: 'has_stock',
    customRender: (value, item, index) => {
      return item.has_stock ? '有' : '无'
    },
  },
  {
    title: '仓库',
    dataIndex: 'warehouse_name',
    key: 'warehouse_name',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]
